<template>
  <div class="unsubscribe-content">
    <template v-if="showUnsubscribeForm">
      <PageHeader
        :header-text="unsubscribePageHeader"
        header-class="unsubscribe-header mb-5"
      />
      <hr>
      <br>
      <div class="unsubscribe-page-text">
        <div
          v-for="(pageTextBlock, i) in unsubscribePageText"
          :key="'text-block-' + i"
        >
          {{ pageTextBlock }}
        </div>
      </div>
      <div class="unsubscribe-display-name">
        Your email address: {{ emailAddress }}
      </div>
      <div
        class="nav-link unsubscribe-change-details-link"
        @click="$emit('change-details')"
      >
        Change email address
      </div>
      <template v-if="unsubscribePageType === 'standard'">
        <div class="unsubscribe-reason-title">
          Please let us know why you’re unsubscribing:
        </div>
        <v-radio-group
          v-model="unsubscribeReason"
          class="unsubscribe-reasons-group"
        >
          <v-radio
            value="Too many emails"
            label="Too many emails"
            class="unsubscribe-reason"
          />
          <v-radio
            label="Subject matter not relevant to me"
            value="Subject matter not relevant to me"
            class="unsubscribe-reason"
          />
          <v-radio
            label="I didn’t sign up for these emails"
            value="I didn’t sign up for these emails"
            class="unsubscribe-reason"
          />
          <v-radio
            label="I’m already receiving these emails with another email address"
            value="I’m already receiving these emails with another email address"
            class="unsubscribe-reason"
          />
          <v-radio
            label="Other"
            value="other"
            class="unsubscribe-reason"
          />
        </v-radio-group>
        <slot />
        <button
          type="button"
          role="button"
          class="unsubscribe-button"
          @click="onUnsubscribe"
        >
          <span class="button-content">Unsubscribe</span>
        </button>
        <div class="d-flex justify-center mt-4">
          <button
            type="button"
            role="button"
            class="unsubscribe-cancel-button"
            @click="showUnsubscribeForm = false;"
          >
            I changed my mind, I want to stay
          </button>
        </div>
      </template>
      <template v-else>
        <div class="mb-5">
          <v-checkbox
            v-for="segment in unsubscribeSegments"
            :key="'opt-out-topic' + segment.id"
            v-model="selectedSegments"
            hide-details
            :label="segment.name"
            :value="segment.id"
            class="unsubscribe-topic"
          />
        </div>
        <slot />
        <v-row class="mt-5">
          <v-col cols="12" md="6">
            <button
              type="button"
              role="button"
              class="unsubscribe-button unsubscribe-opt-out-all-button"
              @click="showConfirmModal = true"
            >
              <span class="button-content">Unsubscribe from All</span>
            </button>
          </v-col>
          <v-col cols="12" md="6">
            <button
              type="button"
              role="button"
              class="unsubscribe-button unsubscribe-opt-out-topics-button"
              @click="onUnsubscribeTopics"
            >
              <span class="button-content">Submit</span>
            </button>
          </v-col>
        </v-row>
      </template>
    </template>

    <div v-else class="thank-you">
      <strong>Thank you!</strong><br>
      You may close this window.
    </div>
    <v-dialog v-model="showConfirmModal" scrollable max-width="350px">
      <ActionConfirmationDialog
        header="Confirm"
        subheader="Are you sure you want to unsubscribe from ALL topics listed on the preference center?"
        action-button-name="Confirm"
        cancel-button-name="Cancel"
        action-button-class="unsubscribe-button unsubscribe-modal-confirm-button"
        cancel-button-class="unsubscribe-button unsubscribe-modal-cancel-button"
        @action="onUnsubscribe(); showConfirmModal = false;"
        @dismiss="showConfirmModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ActionConfirmationDialog from "@/sharedComponents/ActionConfirmationDialog";

export default {
  name: "Unsubscribe",
  components: {
    PageHeader,
    ActionConfirmationDialog,
  },
  props: {
    emailAddress: {
      default: '',
      type: String,
    },
    personalizationSettings: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      unsubscribeReason: 'Unknown',
      showUnsubscribeForm: true,
      selectedSegments: [],
      showConfirmModal: false,
    };
  },
  computed: {
    unsubscribePageHeader() {
      let header = this.personalizationSettings?.unsubscribePageHeader ?? '';

      if (header) {
        return header;
      }

      if (this.unsubscribePageType === 'segment') {
        return 'Preference Center';
      }

      return 'Unsubscribe';
    },
    unsubscribePageText() {
      let text = this.personalizationSettings?.unsubscribePageText ?? '';
      if (text) {
        return text.split("\n");
      }

      if (this.unsubscribePageType === 'segment') {
        return ['We value your time and want to tailor our communications based on your preferences. '
          + 'Please select which email topics you\'d like to receive:'];
      }

      return ["We’re sorry to see you go! Are you sure you want to miss out on important updates from us?"];
    },
    unsubscribeSegments() {
      return this.personalizationSettings?.segments ?? [];
    },
    unsubscribePageType() {
      let type = this.personalizationSettings?.type ?? '';

      return type ? type : 'standard';
    },
  },
  watch: {
    personalizationSettings: {
      deep: true,
      handler() {
        this.updateConfiguration();
      },
    },
  },
  created() {
    this.updateConfiguration();
  },
  methods: {
    updateConfiguration() {
      if (this.personalizationSettings.customStyle !== undefined) {
        let style = document.getElementById('custom-styling');

        if (!style) {
          style = document.createElement('style');
          style.id = 'custom-styling';
          document.head.appendChild(style);
        }

        style.innerText = document.createTextNode(
          this.personalizationSettings.customStyle
        ).wholeText.replace('<br>', '\n');
      }

      if (this.personalizationSettings?.type === 'segment' && this.personalizationSettings?.segments?.length > 0) {
        this.selectedSegments = this.personalizationSettings.segments
          .filter(segment => segment.segmentContactId && !segment.isExcluded)
          .map(segment => segment.id);
      }
    },
    onUnsubscribeTopics() {
      this.$emit('unsubscribe', {
        reason: this.unsubscribeReason,
        isFullOptOut: false,
        segments: this.unsubscribeSegments.map(segment => ({
          id: segment.id,
          name: segment.name,
          isExcluded: !this.selectedSegments.includes(segment.id),
        })),
      });
    },
    onUnsubscribe() {
      this.$emit('unsubscribe', {
        reason: this.unsubscribeReason,
        isFullOptOut: true,
        segments: [],
      });
    },
  },
};
</script>
