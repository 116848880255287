<template>
  <v-container class="new-personalization-setting mt-5 px-10" fluid>
    <PageHeader header-text="Personalization Settings" class="mb-7">
      <template #subheader>
        <div>Customize your unsubscribe page</div>
      </template>
    </PageHeader>

    <div class="d-flex align-center mb-7">
      <div
        v-if="!personalizationSettings.logoFile && !personalizationSettings.logoUrl"
        class="upload-background d-flex align-center justify-center"
        @click="$refs.logo_container.click()"
      >
        <input
          ref="logo_container"
          type="file"
          accept=".jpg, .jpeg, .png"
          style="width: 0; height: 0"
          @change="handleFileUpload"
        >
        <v-icon size="18" color="66788e">
          mdi-upload-outline
        </v-icon>
      </div>
      <div v-else class="upload-background d-flex align-center justify-center">
        <img
          v-if="personalizationSettings.logoUrl"
          :src="personalizationSettings.logoUrl"
          alt="Personalization Setting logo"
          style="max-width: 92px; max-height: 92px;"
        >
        <img
          v-else-if="personalizationSettings.logoFile"
          ref="logo_preview"
          src=""
          style="max-width: 92px; max-height: 92px;"
          alt="Personalization Setting Logo"
        >
        <v-icon
          size="24" color="warning"
          style="position: absolute;"
          @click="removeLogo()"
        >
          mdi-close
        </v-icon>
      </div>
      <div>
        <div class="title-text mb-1">
          Logo
        </div>
        <div class="text-field-hint">
          Upload your logo to be displayed
        </div>
      </div>
    </div>

    <validation-observer v-slot="{ invalid }">
      <v-row class="py-0">
        <v-col cols="12" sm="12" class="py-0">
          <CustomTextInput
            v-model="personalizationSettings.unsubscribePageHeader"
            header="Unsubscribe Page Header"
            hide-details
            placeholder="Unsubscribe Page Header"
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomDropdown
            v-model="personalizationSettings.type"
            header="Unsubscribe Page Type"
            hide-details
            required
            placeholder="Select a Type"
            :items="types"
          />
        </v-col>
        <v-col
          v-if="personalizationSettings.type === 'segment'"
          cols="12"
          sm="6"
          class="py-0"
        >
          <CustomDropdown
            v-model="personalizationSettings.segments"
            header="Opt Out Topics/Segments"
            hide-details
            multiple
            autocomplete
            short
            :required="personalizationSettings.type === 'segment'"
            placeholder="Select your Segments"
            item-value="id"
            item-text="name"
            :items="segments"
          />
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextArea
            v-model="personalizationSettings.unsubscribePageText"
            header="Unsubscribe Content"
            hide-details
            placeholder="Unsubscribe Content"
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextArea
            v-model="personalizationSettings.footerText"
            header="Footer Text"
            hide-details
            placeholder="Footer Text"
          />
        </v-col>
        <v-col cols="12" sm="12" class="py-0">
          <CustomTextArea
            v-model="personalizationSettings.customStyle"
            header="CSS"
            hide-details
            placeholder="CSS"
          />
        </v-col>
      </v-row>

      <v-row class="mt-12 mb-10">
        <v-col cols="12" class="d-flex justify-end flex-wrap">
          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            width="150px"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="invalid"
            @click="savePersonalizationSetting"
          >
            Save Settings
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <hr>
    <br>

    <strong>Page Preview:</strong>

    <div class="page-preview">
      <v-container
        fluid
        class="unsubscribe-background"
      >
        <v-container
          class="unsubscribe-container"
        >
          <div
            v-if="personalizationSettings.logoUrl !== null && personalizationSettings.logoUrl !== undefined"
            class="unsubscribe-logo-container"
          >
            <img
              :src="personalizationSettings.logoUrl"
              alt="Personalization Setting logo"
              class="unsubscribe-logo-img"
            >
          </div>
          <div
            v-else-if="logoPreview"
            class="unsubscribe-logo-container"
          >
            <img
              :src="logoPreview"
              alt="Personalization Setting logo"
              class="unsubscribe-logo-img"
            >
          </div>
          <div
            class="unsubscribe-content-container"
          >
            <Unsubscribe
              email-address="john.doe@pulsehealth.tech"
              :personalization-settings="previewPersonalizationSettings"
            />
          </div>
          <div
            v-if="personalizationSettings.footerText !== null && personalizationSettings.footerText !== undefined"
            class="unsubscribe-footer-text"
          >
            <div
              v-for="(footerTextBlock, i) in footerText"
              :key="'footer-text-block-' + i"
            >
              {{ footerTextBlock }}
            </div>
          </div>
        </v-container>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextArea from "@/sharedComponents/CustomTextareaV2";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import {parseImageFileToBase64} from "@/utils/helper";
import Unsubscribe from "@/views/Unsubscribe/components/Unsubscribe";

export default {
  name: "PersonalizationSettingsView",
  metaInfo: {
    title: 'Personalization Settings'
  },
  components: {
    PageHeader,
    CustomTextArea,
    CustomDropdown,
    CustomTextInput,
    Unsubscribe,
  },
  data() {
    return {
      accountId: null,
      logoPreview: '',
      personalizationSettings: {
        type: 'standard',
        segments: [],
        logoFile: null,
        logoUrl: null,
        unsubscribePageText: null,
        customStyle: null,
        footerText: null,
      },
      types: [
        { label: 'Standard', value: 'standard' },
        { label: 'Topic/Segment', value: 'segment' },
      ],
      segments: [],
    };
  },
  computed: {
    previewPersonalizationSettings() {
      return {
        ...this.personalizationSettings,
        segments: this.personalizationSettings.segments.map(segmentId => ({
          id: segmentId,
          name: this.segments.find(segment => parseInt(segment.id) === parseInt(segmentId))?.name,
          segmentContactId: null,
          isExcluded: false,
        })),
      }
    },
    footerText() {
      if (this.personalizationSettings.footerText === null || this.personalizationSettings.footerText === undefined) {
        return [];
      }

      return this.personalizationSettings.footerText.split("\n");
    },
  },
  async created() {
    const { data } = await this.getPersonalizationSetting();
    this.accountId = data.accountId;
    this.setCurrentPersonalizationSetting(data.personalizationSettings, data.personalizationLogoUrl);
    await this.loadSegments();
  },
  methods: {
    async loadSegments() {
      const response = await this.$rest.segment.get_collection({
        ignorePagination: true,
        isArchived: false,
        sort: ['name:asc'],
      }, {
        headers: {
          'X-Account': this.accountId,
        },
      });

      if (!response.data) {
        return;
      }

      this.segments = response.data.items.map(item => ({
        id: item.id,
        name: item.name,
      }));
    },
    async getPersonalizationSetting() {
      return await this.$rest.account.get_resource(+this.$route.params.id);
    },
    setCurrentPersonalizationSetting(personalizationSetting, logoUrl) {
      this.personalizationSettings = {
        ...this.personalizationSettings,
        ...JSON.parse(JSON.stringify(personalizationSetting)),
        logoUrl
      };

      this.personalizationSettings = {
        ...this.personalizationSettings,
        type: this.personalizationSettings?.type ?? 'standard'
      };
    },
    removeLogo() {
      this.personalizationSettings.logoFile = null;
      this.personalizationSettings.logoUrl = null;
    },
    async handleFileUpload(event) {
      let file = null;
      if (event.target.files.length) {
        file = event.target.files[0];
      }

      if (!(file instanceof File)) {
        this.personalizationSettings.logoFile = null;
        this.$refs.logo_preview.src = '';
        return;
      }
      parseImageFileToBase64(file).then(imgLoaded => {
        this.personalizationSettings.logoFile = {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        };
        this.$nextTick(() => {
          this.$refs.logo_preview.src = this.logoPreview = imgLoaded.data;
        })
      }).catch(() => {
        this.personalizationSettings.logoFile = null;
        this.$refs.logo_preview.src = '';
      });
    },
    async savePersonalizationSetting() {
      if (typeof this.personalizationSettings.logoFile !== 'object') {
        this.personalizationSettings.logoFile = null;
      }
      const data = {
        ...this.personalizationSettings,
      };
      delete data.logoUrl;

      await this.$rest.account.put_personalization_settings_resource(this.accountId, data);

      this.$store.commit('snackbar/showMessage', {
        color: "success",
        content: "Personalization Settings successfully added.",
      });

      await this.$router.push({name: "EditAccount", params: { id: $route.params.id }});
    },
  },
};
</script>

<style scoped lang="scss">
.new-personalization-setting {
  .upload-background {
    cursor: pointer;
    width: 95px;
    min-width: 95px;
    height: 95px;
    margin: 0 14px 0 0;
    border-radius: 3px;
    background-color: #ecf1f5;
    * {
      cursor: pointer;
    }
  }

  .v-input.v-input--selection-controls {
    margin: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
